DO.Subscribe('app:ready', function(e, $) {
	"use strict";

	function init() {
		// attachHeader();
		attachFooter();
	}

	function attachHeader() {
		// handle smaller header on scroll
		$(window).on('scroll', function(e) {
			if($(window).scrollTop() >= $('.header').outerHeight() + 30) {
				$('html').addClass('header-sticky');
			} else {
				$('html').removeClass('header-sticky');
			}
		});
	}

	function attachFooter() {
		if($('body').outerHeight() < $(window).height() + 1500) {
			$('.scrolltop').hide();
		}

		$('[data-scrolltop]').on('click', function(e) {
			e.preventDefault();
			$('html, body').animate({scrollTop:0}, 500);
		});
	}

	init();

});
DO.Subscribe('app:ready', function(e, $) {
	"use strict";

	function init() {
		attachMasonry();
	}

	function attachMasonry() {
		var grid = $('[data-masonry]').masonry({
		  // options
		 // itemSelector: '> div',
			percentPosition: true
		});

		grid.imagesLoaded().progress( function() {
  		grid.masonry('layout');
		});

	}

	init();

});

DO.Subscribe('app:ready', function(e, $) {
	"use strict";

	function init() {
		attachEvents();
	}

	function attachEvents() {
		$('.hamburger').each(function() {
			this.addEventListener('ToggleSwitch.toggled', function() {
				setTimeout(function() {
					if($('body').hasClass('menu-active')) {
						$('.hamburger--nav').focus();

					} else {
						$('.hamburger--header').focus();

					}
				}, 300);
			});
		});
	}

	init();

});
DO.Subscribe('app:ready', function(e, $) {
	"use strict";

	function init() {
		requiredFields();
	}

	function requiredFields() {
		var inputs = $(this).find(':input[required]');

		inputs.each(function() {
			var parent;
			if($(this).closest('.fieldgroup-field').length) {
				parent = $(this).closest('.fieldgroup-field');
				parent.prepend('<span class="required-label">Required</span>');

			} else if($(this).closest('.middleColumn').length) {
				parent = $(this).closest('.middleColumn');
				parent.before('<span class="required-label">Required</span>');

			} else {
				parent = $(this).closest('div.field');
				parent.before('<span class="required-label">Required</span>');
			}

		});
	}



	init();

});
// SITEMAP
DO.Subscribe('app:ready', function(e, $) {
	"use strict";


	function init() {
		attachEvents();
	}

	function attachEvents() {
		$('.sitemap').on('click', '.button', function() {
			var self = $(this),
				target = $(self.attr('data-target'));

			// only do an ajax request if the content isn't loaded
			if(target.html().length === 0) {
				requestAjax(target, self);
			}

			toggleChildren(target, self);

			return false;
		});
	}

	function requestAjax(target, self) {
		self.addClass('loading');

		$.ajax({
			url: self.attr('href'),
			data: { ajax: true }
		}).done(function(data) {
			target.html(data);
			self.removeClass('loading');
		});
	}


	function toggleChildren(target, self) {
		self.toggleClass('open');

		if(self.hasClass('open')) {
			target.removeClass('collapse').addClass('collapsed');
			self.attr('aria-expanded', 'true');
			self.children('.linkText').replaceWith('<span class="linkText">Collapse section</span>');
		} else {
			target.removeClass('collapsed').addClass('collapse');
			self.attr('aria-expanded', 'false');
			self.children('.linkText').replaceWith('<span class="linkText">Expand section</span>');
		}
	}

	init();
});

/**
* Slick slider init
*
*/
DO.Subscribe('app:ready', function(e, $) {

	"use strict";

	function init() {
		setup($(document));
	}


	function setup(scope) {
		if(scope.find('[data-slider]').length < 1) {
			return;
		}

		scope.find('[data-slider]').slick(getConfig());

		attachEvents(scope);
	}

	function attachEvents(scope) {
		if(scope.is(document)) {
			$(document).on('ajaxpages:contentloaded', function(e, data) {
				setup(data.target);
			});
		}
	}

	//[0, 568, 768, 1024, 1280]
	function getConfig() {
		return {
			mobileFirst: true,
			variableWidth: false,
			infinite: false,
			slidesToShow: 1,
			arrows: false,
			dots: true,
			adaptiveHeight: false,
			prevArrow: '<button type="button" class="pure-button pure-button-icononly slick-prev"><span class="sr-only">Previous</span></button>',
			nextArrow: '<button type="button" class="pure-button pure-button-icononly slick-next"><span class="sr-only">Next</span></button>',
			responsive: [{
				breakpoint: 1280,
				settings: {
					arrows: true,
					dots: false
				}
			}, {
				breakpoint: 768,
				settings: {
					arrows: false,
					dots: true
				}
			}]
		};
	}

	init();

});

/**
 * Toggles and switches extra js (for accessibility). 
 * Uses ../lib/toggles-switches.js from https://github.com/dsurgeons/Toggles-Switches
 */

 DO.Subscribe('app:ready', function(e, $) {

	"use strict";

	var focusable = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]',
		focusableNative = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed';

	/**
	 * Initialize Button actions
	 */
	function init() {
		setup($(document));
	}

	/**
	 * Apply setup to our newly loaded area (document, or scope)
	 *
	 * @param scope | DOMObject - the area of the page we are applying this functionality to
	 */
	function setup(scope) {
		// if aria hidden, we shouldn't be able to focus
		scope.find('[aria-hidden="true"]').find(focusable).attr('tabindex', '-1');

		attachEvents(scope);
	}


	/**
	 * If an element has the attribute data-toggle-aria we want to toggle the
	 * aria-hidden attribute of the data-toggle-aria value and the tabindex of
	 * all focusable children.
	 * @param scope | DOMObject
	 */
	function attachAriaHide(scope) {
		var targets = scope.find("[data-toggle-aria]"),
			i;

		// Set aria hidden and tabindex -1
		targets.on('click', function() {
			var target = $(this).data('toggle-aria'),
				value = $(target).attr('aria-hidden') == "false" ? "true" : "false",
				tabindex = value == "false" ? "0" : "-1";

			$(target).attr('aria-hidden', value);
			$(target).find(focusable).attr('tabindex', tabindex);
		});
	}

	/**
	 * Attach events
	 * @param scope | DOMObject
	 */
	function attachEvents(scope) {
		attachAriaHide(scope);

		scope.find('[data-contain]').on('click', function(e) {
			e.stopPropagation();
		});

		//make sure we get the order of our events right
		setTimeout(function(){
			keyboardFocus(scope);
		}, 1000);

		if(scope.is(document)) {

			$(document).on('ajaxpages:contentloaded', function(e, data) {
				setup(data.target);
			});
		}
	}

	/**
	 * Allow a focus state that only appears for people using keyboards to navigate
	 * @param scope | DOMObject
	 */
	function keyboardFocus(scope) {
		scope.find(focusable).on('keyup',function(e) {
			$(e.target).addClass("hasfocus");

			if($(e.target).is(focusableNative)){
				return;
			}

			// trigger click if this element doesn't usually support click
			if(e.keyCode == 13) {
				e.preventDefault();
				e.stopPropagation();
				$(e.target).trigger('click');
			}

		}).on('blur focusout',function(e) {
			$(e.target).removeClass("hasfocus");
		});
	}

	init();

});

DO.Subscribe('app:ready', function(e, $) {
	"use strict";

	function init() {
		attachValidation();
	}


	function attachValidation() {

	}

	init();

});

window.ParsleyConfig = {
	trigger: 'focusout selectfocus change submit',
	errorsContainer: function(el) {
		var $el = el.$element,
			field = $el.parents('div.composite_tight').first();

		if(!field.length) {
			field = $el.parents('div.field').first();
		}

		return field;
	},
	excluded: 'input[type=button], input[type=submit], input[type=reset]',
	inputs: 'input, textarea, select, input[type=hidden]'
}

/*
 * Listen for parsley invalidation
 */
$.listen('parsley:field:success', function (el){
	var $el = el.$element,
		field = $el.parents('div.composite_tight').first();

	if(!field.length) {
		field = $el.parents('div.field').first();
	}

	if(field.find('.parsley-error').not($el).length == 0) {
		field.removeClass('has_error');
	}
});

$.listen('parsley:field:error', function (el){
	var $el = el.$element,
		field = $el.parents('div.composite_tight').first();

	if(!field.length) {
		field = $el.parents('div.field').first();
	}

	field.addClass('has_error');
});

$.listen('parsley:field:validated', function(el) {
	// console.log('Name: ' + el.$element.prop('name'));

	// case for hidden selects for bootstrap, but
	// hidden for some other reason
	if(el.$element.is(":hidden") && el.$element.closest('.middleColumn').is(":hidden")) {
		// hide the message wrapper
		el._ui.$errorsWrapper.css('display', 'none');
		// set validation result to true
		el.validationResult = true;
		return true;
	}
});



